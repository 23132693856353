export const entity = {
    name: 'NatureOfHelp',
    facets: ['codeName'],
    fields: [
        {path: 'eligibleToEmergencyFund', type: 'boolean'},
        {path: 'facilityRelated', type: 'boolean'},
    ],
    filters: [
        {
            name: 'eligibleToEmergencyFund',
            isDefault: false,
            query: () => ({eligibleToEmergencyFund: true})
        },
        {
            name: 'facilityRelated',
            isDefault: false,
            query: () => ({facilityRelated: true})
        }
    ]
}


export const module_ = {
    object: 'NatureOfHelp',
    tKey: 'mTitle_natureOfHelp',
    category: {
        path: 'configuration',
        icon: 'settings'
    },
    viewMap: {
        dt: [
            {path: 'code'},
            {path: 'name'},
            'eligibleToEmergencyFund',
            'facilityRelated'
        ],
        form: [
            {path: 'code'},
            {path: 'name'},
            'eligibleToEmergencyFund',
            'facilityRelated'
        ]
    }
}
