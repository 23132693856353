import _ from "lodash";
import {basicContext} from "../../../../utils/contextUtils";
import {changeFieldProperty, setFieldVisibility} from "../../../../../apps/KpModule/actions";

export const module_ = {
    name: 'FollowUp',
    object: 'ReliefFund',
    tKey: 'Suivi dossier',
    category: {path: 'Fonds de secours', icon: 'icon_path'},
    newable: false,
    removable: false,
    viewMap: {
        dt: [
            {path: 'applicationNumber', tKey: 'N° du dossier'},
            {path: 'object'},
            {path: 'needsTypes'},
            {path: 'totalRequestedAmounts', tooltip: true},
            {path: 'totalAwardedAmounts', tooltip: true},
            {path: 'totalValidatedAmounts', tKey: 'dont versé (€)', tooltip: true},
            {path: 'paymentRate', tooltip: true},
            {path: 'status', type: 'status', fieldPath: ['id', 'name', 'bStyle'], translate: true}
        ],
        form: {
            fields: [
                {path: 'object', editable: false},
                {
                    path: 'needs',
                    tKey: 'Besoins',
                    type: 'accordion',
                    newable: false,
                    viewMap: {
                        dt: [
                            { path: 'natureOfHelp'},
                            { path: 'totalAmount' },
                            { path: 'requestedAmount' },
                            { path: 'awardedAmount'},
                            { path: 'validatedAmounts', tKey: 'Dont versé (€)'},
                        ],
                        form: [
                            {
                                path: 'natureOfHelp',
                                editable: false,
                                fieldPath: ['id', 'name', 'eligibleToEmergencyFund', 'facilityRelated'],
                                subscriptions: {
                                    onChange: (newValue, oldValue, {store, module}) => {
                                        const needsField = module.viewMap.form.fields.find(field => field.path === 'needs')
                                        const landTypeField = needsField.viewMap.form.fields.find(field => field.path === 'landType')

                                        store.dispatch(setFieldVisibility(landTypeField.id, !!newValue && newValue.facilityRelated))
                                    }
                                }
                            },
                            {
                                path: 'landType', tKey: "Le dossier concerne un lieu", display: 'name', required: true, editable: false, translate: true, sortList: false,
                                hidden: true,
                                subscriptions: {
                                    onChange: (newValue, oldValue, {store, module}) => {
                                        const needsField = module.viewMap.form.fields.find(field => field.path === 'needs')
                                        const landTypeProofField = needsField.viewMap.form.fields.find(field => field.path === 'landTypeProof')
                                        store.dispatch(setFieldVisibility(landTypeProofField.id, !!newValue))
                                        store.dispatch(changeFieldProperty(landTypeProofField.id, 'tKey', `landTypeProof_${newValue?.id}`))
                                    }
                                }
                            },
                            { path: 'totalAmount', editable: false },
                            { path: 'requestedAmount', editable: false },
                            { path: 'awardedAmount', editable: false },
                            { path: 'validatedAmounts', tKey: 'Dont versé (€)', editable: false},
                            { path: 'landTypeProof', required: true, fieldPath: ['id', 'filename', 'user.id', 'user.name'], type: 'simpleFiles', editable: false, hidden: true },
                            { path: 'supportingDocuments', fieldPath: ['id', 'filename', 'user.id', 'user.name'], type: 'simpleFiles', tKey: "needSupportingDocuments" },
                        ]
                    }
                },
                {path: 'comments', hidden: true},
                {path: 'buttons', hidden: true}
            ]
        }
    },
    filters: [
        'userHasAuthorization',
        {
            name: 'excludeDraft',
            query: () => {
                return { status: { $ne: 'draft' } }
            }
        },
        {
            title: "Status",
            path: "status",
            tKey: "Statut",
            translate: true,
            type: 'tags',
            default: [{id: 'accepted'}, {id: 'paid'}],
            filters: [
                {
                    name: 'excludeDraftAndIncomplete',
                    match: object => {
                        return !['draft', 'questioned'].includes(object.id)
                    }
                }
            ],
            object: "ReliefFundApplicationStatus",
            display: "name",
            client: true,
            isDefault: false,
            query: function (context) {
                const statusArray = _.get(context, "data.status")

                return statusArray
                    ? {status: {$in: statusArray.map(o => o.id)}}
                    : {status: false}
            }
        },
        {
            path: "authorizedFiles",
            isDefault: false,
            async: true,
            query: async (context, callback) => {
                try {
                    const cUser = await global.app.C.CUser.get({kpUser: global.ObjectID(context.user.id)}, {
                        ...basicContext(context),
                        fieldPath: [
                            'id',
                            'functions.id',
                            'functions.submissionAuthorization',
                            'functions.studyAuthorization',
                            'functions.decisionAuthorization',
                            'functions.paymentAuthorization',
                            'organization.id',
                            'shelters.id'
                        ]
                    })
                    const userIsAdmin = ['studyAuthorization', 'decisionAuthorization', 'paymentAuthorization'].some(
                        key => cUser.functions.some(
                            userFunction => !!userFunction[key]
                        )
                    )
                    const userIsSubmitter = cUser.functions.some(
                        userFunction => !!userFunction['submissionAuthorization']
                    )

                    callback(null, userIsAdmin
                        ? {}
                        : userIsSubmitter
                            ? {organization: global.ObjectID(cUser.organization.id)}
                            : {_id: null}
                    )
                } catch (e) {
                    callback(e)
                }

            }
        },
    ]
}
