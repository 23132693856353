export const entity = {
    name: 'TypeOfProperty',
    fields: [
        {path: 'code', unique: true},
        {path: 'name', unique: true},
        {path: 'category', type: 'boolean'},
        {path: 'natureOfProperty', type: 'boolean'}
    ],
    filters: [
        /*
        {
            name: 'eligibleToEmergencyFund',
            isDefault: false,
            query: () => ({eligibleToEmergencyFund: true})
        }
         */
    ]
}


export const module_ = {
    object: 'TypeOfProperty',
    tKey: 'mTitle_typeOfProperty',
    category: {
        path: 'configuration',
        icon: 'settings'
    },
    viewMap: {
        dt: [
            {path: 'code'},
            {path: 'name'},
            {path: 'category', width: 100},
            {path: 'natureOfProperty', width: 100}
        ],
        form: [
            {path: 'code', required: true},
            {path: 'name', required: true},
            {path: 'category', default: false},
            {path: 'natureOfProperty', default: false}
        ]
    }
}
