//import {setFieldVisibility} from "../../../../../apps/KpModule/actions"
//import {organizationActivities} from "../../staticEntities";

import {changeFieldProperty, setFieldListOptions, setFieldVisibility} from "../../../../../apps/KpModule/actions";
import {getDataListList} from "../../../../../apps/KpModule/selectors";
import _ from "lodash";

export const module_ = {
    object: 'LiberalityFile',
    name: 'LiberalityDecision',
    tKey: 'Décision',
    removable: false,
    newable: false,
    objectIdentifier: 'applicationNumber',
    category: {
        path: 'Libéralités',
        icon: 'folder'
    },
    viewMap: {
        dt: [
            {path: 'applicationNumber', tKey: 'N° dossier'},
            {path: 'presentationDate', tKey: 'Présentation'},
            {path: 'administrator', display: 'fullName', tKey: 'Gestionnaire'},
            {path: 'benefactor'},
            {path: 'typeOfFile', display: 'code'},
            {path: 'beneficiaries', tKey: 'Bénéficiaire(s)'},
            {path: 'netAssets', tKey: 'Actif net (€)', tooltip: true},
            {path: 'CNDAShare', tKey: 'Part CNDA'},

            {path: 'status', type: 'status', fieldPath: ['id', 'name', 'bStyle'], width: 280},
            {path: 'updatedAt', tKey: 'Date'},
            {path: 'updatedBy', tKey: 'Utilisateur'},
        ],
        form: {
            fields: [
                {path: 'applicationNumber', tKey: 'N° dossier', disabled: true},
                {path: 'presentationDate', tKey: 'Date de présentation'},

                {
                    path: 'typeOfFile',
                    display: 'fullName',
                    section: "partiesSection",
                    disabled: true,
                    subscriptions: {
                        onChange: (newValue, oldValue, { module, store, getObjectSuccessAction }) => {
                            if(newValue && getObjectSuccessAction) {
                                const isLegs = newValue.id === 'legs'
                                const isLifeInsurance = newValue.id === 'lifeInsurance'

                                const grossAssetsField = module.viewMap.form.fields.find(field => field.path === 'grossAssets')
                                const grossAssetsDetailsField = module.viewMap.form.fields.find(field => field.path === 'grossAssetsDetails')

                                const netAssetsField = module.viewMap.form.fields.find(field => field.path === 'assets')
                                const netAssetsDetailsField = module.viewMap.form.fields.find(field => field.path === 'assetsDetails')


                                const liabilitiesField = module.viewMap.form.fields.find(field => field.path === 'grossLiabilities')
                                const liabilitiesDetailsField = module.viewMap.form.fields.find(field => field.path === 'grossLiabilitiesDetails')
                                const actFeesField = module.viewMap.form.fields.find(field => field.path === 'actsFees')
                                const actFeesDetailsField = module.viewMap.form.fields.find(field => field.path === 'actsFeesDetails')

                                store.dispatch(setFieldVisibility(grossAssetsField.id, !isLifeInsurance))
                                store.dispatch(setFieldVisibility(grossAssetsDetailsField.id, !isLifeInsurance))

                                store.dispatch(setFieldVisibility(netAssetsField.id, isLifeInsurance))
                                store.dispatch(setFieldVisibility(netAssetsDetailsField.id, isLifeInsurance))

                                store.dispatch(setFieldVisibility(liabilitiesField.id, isLegs))
                                store.dispatch(setFieldVisibility(liabilitiesDetailsField.id, isLegs))
                                store.dispatch(setFieldVisibility(actFeesField.id, !isLifeInsurance))
                                store.dispatch(setFieldVisibility(actFeesDetailsField.id, !isLifeInsurance))



                                const repartitionField = module.viewMap.form.fields.find(field => field.path === 'distribution')

                                const ownLiabilitiesListField = repartitionField.viewMap.dt.fields.find(field => field.path === 'ownLiabilities')
                                const ownLiabilitiesFormField = repartitionField.viewMap.form.fields.find(field => field.path === 'ownLiabilities')

                                const qualificationListField = repartitionField.viewMap.dt.fields.find(field => field.path === 'qualification')
                                const qualificationFormField = repartitionField.viewMap.form.fields.find(field => field.path === 'qualification')

                                const typeOfPropertyListField = repartitionField.viewMap.dt.fields.find(field => field.path === 'typesOfProperty')
                                const typeOfPropertyFormField = repartitionField.viewMap.form.fields.find(field => field.path === 'typesOfProperty')

                                store.dispatch(setFieldVisibility(ownLiabilitiesListField.id, isLegs))
                                store.dispatch(setFieldVisibility(ownLiabilitiesFormField.id, isLegs))

                                store.dispatch(setFieldVisibility(qualificationListField.id, isLegs))
                                store.dispatch(setFieldVisibility(qualificationFormField.id, isLegs))

                                store.dispatch(setFieldVisibility(typeOfPropertyListField.id, !isLifeInsurance))
                                store.dispatch(setFieldVisibility(typeOfPropertyFormField.id, !isLifeInsurance))

                                const typeOfPropertyDisplay = isLegs ? 'categories' : 'naturesOfProperty'

                                store.dispatch(changeFieldProperty(typeOfPropertyListField.id, 'tKey', typeOfPropertyDisplay))
                                store.dispatch(changeFieldProperty(typeOfPropertyFormField.id, 'tKey', typeOfPropertyDisplay))
                            }
                        }
                    }
                },
                {path: 'administrator', display: 'fullName', tKey: 'Gestionnaire', filters: ['eligibleToLiberalityProcess'], section: "partiesSection", disabled: true},

                {path: 'benefactor', section: "partiesSection", required: true, disabled: true},
                {path: "comment", type: "richTextEditor", section: "partiesSection", disabled: true},

                {path: 'grossAssets', tKey: 'Actif brut (€)', wholePositiveNumber: true, section: "legacySection", default: 0, required: true, disabled: true},
                {path: 'grossAssetsDetails', tKey: 'Détails liés à l’actif brut', type: "richTextEditor", section: "legacySection", disabled: true},

                {path: 'assets', tKey: 'Actif (€)', wholePositiveNumber: true, section: "legacySection", default: 0, required: true, disabled: true},
                {path: 'assetsDetails', tKey: 'Détails liés à l’actif (€)', type: "richTextEditor", section: "legacySection", disabled: true},

                {path: 'grossLiabilities', tKey: 'Passif successoral (€)', wholePositiveNumber: true, section: "legacySection", default: 0, required: true, disabled: true},
                {path: 'grossLiabilitiesDetails', tKey: 'Détails liés au passif successoral', type: "richTextEditor", section: "legacySection", disabled: true},

                {path: 'actsFees', tKey: 'Frais d’actes (€)', wholePositiveNumber: true, section: "legacySection", default: 0, required: true, disabled: true},
                {path: 'actsFeesDetails', tKey: 'Détails liés aux frais d’actes', type: "richTextEditor", section: "legacySection", disabled: true},

                {
                    path: 'distribution',
                    tKey: 'Répartition',
                    section: "legacySection",
                    disabled: true,
                    viewMap: {
                        dt: [
                            {path: 'confFor', tKey: 'Pour'},
                            {path: 'beneficiary', display: 'zipCodeAndName'},
                            {path: 'qualification', tKey: 'QF'},
                            {path: 'typesOfProperty', tKey: 'categories'},
                            {path: 'ownLiabilities'},
                            {path: 'share', tKey: 'Quote-part'},
                        ],
                        form: [
                            {path: 'confFor', tKey: 'CONF. DEFENSE DE L’ANIMAL POUR'},
                            {path: 'beneficiary', display: 'zipCodeAndName', filters: ['isAssociation'],},
                            {path: 'qualification'},
                            {path: 'typesOfProperty', fieldPath: ['id', 'name', 'category', 'natureOfProperty'], tKey: 'categories'},
                            {path: 'ownLiabilities'},
                            {path: 'share', tKey: 'Quote-part'},
                        ]
                    }
                },

                {path: 'files', tKeyText: "Fichiers"},
                {path: 'comments', tKeyText: "Suivi"},

                {path: 'applicationNumber', hidden: true},
                {path: 'status', hidden: true},
                {path: 'buttons', hidden: true}

            ]
        }
    },
    filters: [{
        path: "awaitingDecision",
        query: () => {
            return {status: 'awaitingDecision'}
        }
    }]
}
