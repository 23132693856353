import React, {useState} from "react";
import {AlignLeft, ChevronRight, Image, MoreHorizontal, Trash} from "react-feather";

const ImageContextMenu = ({ x, y, onClose, onAction, subMenuPosition }) => {
    const [activeSubmenu, setActiveSubmenu] = useState(null);

    const handleMouseEnter = (submenu) => {
        setActiveSubmenu(submenu);
    };

    const handleMouseLeave = () => {
        setActiveSubmenu(null);
    };

    return (
        <div
            className="image-context-menu"
            style={{
                position: 'fixed',
                top: `${y}px`,
                left: `${x}px`,
                background: 'white',
                border: '1px solid #ccc',
                borderRadius: '4px',
                boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
                zIndex: 1000,
            }}
            onMouseLeave={onClose}
        >
            <ul style={{listStyle: 'none', margin: 0, padding: '4px 0'}}>
                {/* Alignment Submenu */}
                <li
                    className="context-menu-item"
                    onMouseEnter={() => handleMouseEnter('alignment')}
                    onMouseLeave={() => handleMouseLeave()}
                >
                    <Image size={20}/>
                    Position
                    <ChevronRight size={18} className="rightIcons"/>
                    {activeSubmenu === 'alignment' && (
                        <div
                            className="submenu"
                            style={{
                                ...subMenuPosition
                            }}
                        >
                            <ul style={{listStyle: 'none', margin: 0, padding: '4px 0'}}>
                                <li
                                    className="context-menu-item"
                                    onClick={() => onAction('alignLeft')}
                                >
                                    <div
                                        style={{
                                            display: 'flex', flexDirection: 'row', gap: '3px',
                                            border: "1px solid",
                                            borderRadius: "10%",
                                            padding: "1px 19px 1px 1px"
                                        }}
                                    >
                                        <Image size={18}/>
                                    </div>
                                    Image à gauche
                                </li>
                                <li
                                    className="context-menu-item"
                                    onClick={() => onAction('alignCenter')}
                                >
                                    <div
                                        style={{
                                            display: 'flex', flexDirection: 'row', gap: '3px',
                                            border: "1px solid",
                                            borderRadius: "10%",
                                            padding: "1px 10px 1px 10px"
                                        }}
                                    >
                                        <Image size={18}/>
                                    </div>
                                    Image au centre
                                </li>
                                <li
                                    className="context-menu-item"
                                    onClick={() => onAction('alignRight')}
                                >
                                    <div
                                        style={{
                                            display: 'flex', flexDirection: 'row', gap: '3px',
                                            border: "1px solid",
                                            borderRadius: "10%",
                                            padding: "1px 1px 1px 19px"
                                        }}
                                    >
                                        <Image size={18}/>
                                    </div>
                                    Image à droite
                                </li>
                                <li
                                    className="context-menu-item"
                                    onClick={() => onAction('alignLeftWithText')}
                                >
                                    <div
                                        style={{
                                            display: 'flex', flexDirection: 'row', gap: '3px',
                                            border: "1px solid",
                                            borderRadius: "10%",
                                            padding: "1px 1px"
                                        }}
                                    >
                                        <Image size={18}/>
                                        <AlignLeft size={15} style={{ alignSelf: 'flex-start'}}/>
                                    </div>
                                    Image + Paragraphe à droite
                                </li>
                                <li
                                    className="context-menu-item"
                                    onClick={() => onAction('alignRightWithText')}
                                >
                                    <div
                                        style={{
                                            display: 'flex', flexDirection: 'row', gap: '3px',
                                            border: "1px solid",
                                            borderRadius: "10%",
                                            padding: "1px 1px"
                                        }}
                                    >
                                        <AlignLeft size={15} style={{alignSelf: 'flex-start'}}/>
                                        <Image size={18}/>
                                    </div>
                                    Paragraphe à gauche + Image
                                </li>
                                <li
                                    className="context-menu-item"
                                    onClick={() => onAction('alignCenterWithText')}
                                >
                                    <div
                                        style={{
                                            display: 'flex', flexDirection: 'row',
                                            border: "1px solid",
                                            borderRadius: "10%",
                                            padding: "2px 1px"
                                        }}
                                    >
                                        <MoreHorizontal size={10} style={{alignSelf: 'flex-end', marginBottom: '2px'}}/>
                                        <Image size={16}/>
                                        <MoreHorizontal size={10} style={{alignSelf: 'flex-end', marginBottom: '2px'}}/>
                                    </div>
                                    Intégrer l'image dans le texte
                                </li>
                            </ul>
                        </div>
                    )}
                </li>

                {/* Remove Image Option */}
                <li
                    className="context-menu-item"
                    onClick={() => onAction('removeImage')}
                >
                    <Trash size={20}/>
                    Supprimer l'image
                </li>
            </ul>
        </div>
    );
};

export default ImageContextMenu