import {useState} from "react";

const TableSelector = ({ onSelect, onClose }) => {
    const [hoveredCell, setHoveredCell] = useState({ row: 0, col: 0 });
    const [isVisible, setIsVisible] = useState(true);
    const maxRows = 8;
    const maxCols = 8;

    const handleMouseMove = (row, col) => {
        setHoveredCell({ row: row + 1, col: col + 1 });
    };

    const handleSelect = () => {
        onSelect({
            rows: hoveredCell.row,
            columns: hoveredCell.col
        });
        setIsVisible(false);
    };

    if (!isVisible) return null;

    return (
        <div className="table-selector-popup">
            <div className="table-selector-header">
                Insert a {hoveredCell.row} × {hoveredCell.col} table
            </div>
            <div className="table-selector-grid">
                {Array.from({ length: maxRows }).map((_, rowIndex) => (
                    Array.from({ length: maxCols }).map((_, colIndex) => (
                        <div
                            key={`${rowIndex}-${colIndex}`}
                            className={`table-selector-cell ${
                                rowIndex < hoveredCell.row && colIndex < hoveredCell.col
                                    ? 'table-selector-cell-selected'
                                    : ''
                            }`}
                            onMouseEnter={() => handleMouseMove(rowIndex, colIndex)}
                            onClick={handleSelect}
                        />
                    ))
                ))}
            </div>
            <button
                onClick={onClose}
                className="table-selector-close"
            >
                ×
            </button>
        </div>
    );
};

export default TableSelector