export const entity = {
    name: 'Bank',
    fields: [
        'name',
        {path: 'bic', encrypted: true},
        {path: 'iban', unique: true, encrypted: true},
        {path: 'temporaryAccount'},
        {path: 'provisionalAccount', unique: true},
        {path: 'definitiveAccount', unique: true},
        {path: 'active', type: 'boolean'},
        {path: "eligibleProcesses", type: "Process", link: 'MTM'},
    ],
    filters: [
        {
            name: 'isEmergencyFundBankAccount',
            isDefault: false,
            query: () => ({eligibleProcesses: {$elemMatch: {$eq: 'emergencyFund'}}})
        }
    ]
}

export const module_ = {
    object: 'Bank',
    tKey: 'mTitle_bank',
    objectIdentifier: 'name',
    category: {
        path: 'configuration',
        icon: 'settings'
    },
    viewMap: {
        dt: [
            {path: 'name'},
            {path: 'iban', initiallyNotVisible: true},
            {path: 'bic', initiallyNotVisible: true},
            {path: 'temporaryAccount', initiallyNotVisible: true},
            {path: 'provisionalAccount', initiallyNotVisible: true},
            {path: 'definitiveAccount', initiallyNotVisible: true},
            {path: 'active'}
        ],
        form: [
            {path: 'name', required: true},
            {path: 'iban', required: true, noSpaces: true, maxLength34: true, bankData: true},
            {path: 'bic', required: true, noSpaces: true, lengthEquals8or11: true, bankData: true},
            {path: 'temporaryAccount', required: true},
            {path: 'provisionalAccount', required: true},
            {path: 'definitiveAccount', required: true},
            {path: 'eligibleProcesses'},
            {path: 'active', default: true}
        ]
    }
}
