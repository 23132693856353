import React from 'react'
import { Field } from 'redux-form'
import { StyledInput } from '../../../../components/Form/StyledInput'
import getFormValidations from './formValidations'
import _ from "lodash"

// binding for redux-form
const Input = ({ input, meta:{touched, error}, required, path, editable, disabled, placeholder, min, max, t }) => {
    const translatedError = t(error)
    return <StyledInput {...input} path={path} required={required} type="number" touched={touched} error={translatedError} disabled={disabled || !editable} placeholder={t(placeholder)} min={min} max={max} t={t} />
}

// the binding function has to be outside to avoid re-rendering
// https://github.com/erikras/redux-form/issues/1094

const FormNumberField = ({ field, t, objectMode }) => {
    const {wholePositiveNumber, wholePositiveNumberStrict, minValue, maxValue} = field
    const min = minValue || (wholePositiveNumberStrict ? 1 : wholePositiveNumber ? 0 : undefined)
    const max = maxValue
    const validations = getFormValidations(field, t)
    return (
        <Field
            name={field.path}
            path={field.tKey || field.path}
            required={field.required}
            component={Input}
            placeholder={field.placeholder}
            validate={validations}
            disabled={field.disabled || !field.writable}
            editable={!field.editable ? objectMode === 'newObject' : field.editable}
            normalize={(value) => {
                if(max && parseFloat(value) > parseFloat(max)) return parseFloat(max)
                if(!_.isUndefined(min) && parseFloat(value) < min) return min
                return parseFloat(value)
            }}
            wholePositiveNumberStrict={field.wholePositiveNumberStrict}
            min={min}
            max={max}
            t={t}
        />
    )
}

export default FormNumberField
