import moment from "moment/moment";
import {GET_OBJECT_SUCCESS} from "../../../../../apps/KpModule/actions/api";
/*
import _ from "lodash";
import {setDataList} from "../../../../../apps/KpModule/actions";
 */
import {getObjectMode} from "../../../../../apps/KpModule/selectors";
import {prepareListButton} from "../utils";
import {returnButton} from "../../utils";
import {setFieldEdition, setFieldVisibility} from "../../../../../apps/KpModule/actions";

const liberalityFilePdfFields = [
    'id', 'applicationNumber', 'typeOfFile.code', 'administrator.fullName', 'benefactor', 'grossAssets', 'assets', 'netAssets', 'grossLiabilities', 'actsFees',
    'distribution.beneficiary.name', 'distribution.qualification.name', 'distribution.share', 'distribution.beneficiary.name',
    'status.id'
]

export const module_ = {
    object: 'LiberalityPresentation',
    name: 'Presentation',
    tKey: 'Présentation',
    objectIdentifier: 'applicationNumber',
    defaultFormButtons: [prepareListButton, returnButton],
    category: {
        path: 'Libéralités',
        icon: 'folder'
    },
    viewMap: {
        dt: [
            {path: 'presentationDate', tKey: 'Présentation'},
            {path: 'filesNumber', tKey: 'Nb dossiers'},
            {path: 'updatedAt', tKey: 'Date'},
            {path: 'updatedBy', tKey: 'Utilisateur'},
            {path: 'table', tKey: 'Tableau'},
        ],
        form: {
            fields: [
                {path: 'presentationDate', tKey: 'Date de présentation', type: 'datePicker', startDate: moment().format('YYYY-MM-DD'), required: true, editable: false},
                {path: 'liberalityFiles', fieldPath: liberalityFilePdfFields, display: 'applicationNumber', tKey: 'Dossiers', filters: ['readyTobePresented'], required: true},
                {path: 'presentationDescription', tKey: 'Présentation', type: 'richTextEditor'},
                {path: 'reportDescription', tKey: 'Relevé de décisions', type: 'richTextEditor', disabled: true},
                {path: 'status', hidden :true},
                {path: 'buttons', hidden :true}
            ],
            onOpen: ({ store }) => {
                const state = store.getState()
                const objectMode = getObjectMode(state)
                const isNewObject = objectMode === 'newObject'

                if(isNewObject) {
                    store.dispatch(setFieldVisibility('e_reportDescription', false))
                }
            }
        }
    },
    actionSubscriptions: [
        {
            actionType: GET_OBJECT_SUCCESS,
            subscription: ({ store }) => {
                const state = store.getState()
                const presentationStatus = state.edit.object.data.status

                store.dispatch(setFieldVisibility('e_liberalityFiles', presentationStatus !== 'closed'))
                store.dispatch(setFieldVisibility('e_reportDescription', presentationStatus === 'closed'))
                store.dispatch(setFieldEdition('e_presentationDescription', presentationStatus !== 'closed'))
            }
        }
    ]
}
