const async = require('async')
async function applyJob() {

    const message = "Reset successful"

    const collections = ['r.exercise', 'r.benefit', 'r.cUser', 'r.rightHolder', 'r.refund', 'r.category', 'r.limit', 'r.bank', 'r.supplier', 'r.contacts',
        'r.requestGroup', 'r.reallocation', 'r.accountStatement', 'r.invoice', 'r.invoiceLine', 'r.endowmentHistory', 'r.accountsGroup', 'r.analyticalCode', 'r.account', 'r.accountsTemplate', 'r.accountingEntries',
        'r.entriesExtraction', 'r.bankFlow', 'r.batch', 'r.reports', 'r.gifts', 'user', 'r.messagingPlatform', 'r.alertModelPlatform', 'r.documentModelPlatform', 'r.broadcastListPlatform', 'r.documentDatabase', 'r.ticket', 'r.ticketSubject'
    ]

    await async.parallel(collections.map(col => callback => {
        global.db.collection(col).find({}).toArray((e, elements) => {
            global.db.collection(`${col}_initial`).deleteMany({}, () => {
                global.db.collection(`${col}_initial`).insertMany(elements, callback)
            })
        })

    }))
    return { message }
}

export const job = {
    name: "initBackup",
    title: "initialize backup",
    execute: function(context, callback) {
        applyJob()
            .then(result => callback(null, result))
            .catch(callback)
    }
}
