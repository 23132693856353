import {getButtonsForModule, prepareDefaultMail, getUsersWithAuthorization} from "./utils"
import {refundApplicationStatusObjects} from '../staticEntities'
import _ from "lodash";
import Errors from "../../../utils/Errors"
import {decrypt} from "../../../utils/crypto"
import {basicContext} from "../../../utils/contextUtils"
import async from "async"
import moment from "moment"
import {getCNDANotificationData} from "../../../utils/notificationData"

export const entities = [
    {
        name: 'Need',
        facets: [
            {name: 'files', path: 'supportingDocuments'},
            {name: 'files', path: 'landTypeProof'},
        ],
        fields: [
            {path: 'natureOfHelp',type: 'NatureOfHelp'},
            {path: 'landType', type: 'LandType', nullable: true},
            {
                path: 'name',
                fieldPath: ['natureOfHelp.name'],
                f: function() {
                    return this.natureOfHelp?.name
                }
            },
            {path: 'form', type: 'Form', nullable: true},
            {path: 'totalAmount', type: 'integer'},
            {path: 'requestedAmount', type: 'integer'},
            {path: 'awardedAmount', type: 'integer', nullable: true},
            {
                path: 'validatedAmounts',
                fieldPath: ['reliefFund.payments.status.id', 'reliefFund.payments.amount', 'reliefFund.payments.need.id'],
                $f: function (need, context, callback) {
                    const total = need.reliefFund.payments
                        .filter(payment => {
                            const isCurrentNeed = need.id === _.get(payment, 'need.id')
                            const isValidated = _.get(payment, 'status.id') === 'validated'
                            return isCurrentNeed && isValidated
                        })
                        .reduce((acc, payment) => {
                            return acc + parseInt(payment.amount)
                        }, 0)
                    callback(null, total)
                }
            },
            /*
            {
                path: "noDeleteButtonAccess",
                fieldPath: ['reliefFund.status.id'],
                $f: function (need, context, callback) {

                    // Check if the application status is NOT 'draft' or 'questioned'
                    const noDeleteAccess = !['draft', 'questioned'].includes(_.get(need, 'reliefFund.status.id'))

                    // Call the callback function with the result
                    // true means no delete access, false means delete access is allowed
                    callback(null, noDeleteAccess)
                }
            }
             */
        ],
        filters: [
            {
                name: 'byReliefFund',
                isDefault: false,
                query: (context) => {
                    const reliedFundId = _.get(context, 'data.reliefFund.id')
                    return reliedFundId
                        ? {reliefFund: global.ObjectID(reliedFundId)}
                        : {_id: null}
                }
            },
            {
                name: 'hasAwardedAmount',
                isDefault: false,
                query: () => {
                    return {awardedAmount: {$gt: 0}}
                }
            }
        ]
    },
    {
        name: 'ReliefFund',
        facets: [
            'comments',
            {name: 'files', path: 'associationUpdatedStatutes'},
            {name: 'files', path: 'associationStatusNotice'},
            {name: 'files', path: 'latestAssociationReport'},
            {name: 'files', path: 'insuranceContract'},
            {name: 'files', path: 'bankIdentityStatement'},
        ],
        fields: [
            {path: 'applicationNumber', type: 'string', unique: true},
            {type: 'Organization', nullable: true},
            {path: 'object', type: 'string'},
            {path: 'description', type: 'string'},
            {path: 'needs', type: 'Need', link: 'OTM'},
            {path: 'payments', type: 'Payment', link: 'OTM'},
            {path: 'associationInformationUpToDate', type: 'boolean'},
            {path: 'shelterInformationUpToDate', type: 'boolean'},
            {path: 'shelterCapacityInformationUpToDate', type: 'boolean'},
            /*
            {path: 'latestAssociationReportAvailable', type: 'boolean'},
            {path: 'associationIbanAndBicAvailable', type: 'boolean'},
            {path: 'associationStatusNoticeAvailable', type: 'boolean'},
            {path: 'associationUpdatedStatutesAvailable', type: 'boolean'},
             */
            {path: 'statementOfHonestyAndAccuracy', type: 'boolean'},
            {path: 'ongoingLiberalities', type: 'boolean'},
            {path: 'currentSuccessionsName'},
            {path: 'estimatedAmount', type: 'integer', nullable: true},
            {path: 'studyCommitteeComment', type: 'string'},
            {path: 'decision', type: 'string'},
            {path: 'comment', type: 'string'},
            {path: 'status', type: 'ReliefFundApplicationStatus', nullable: true},
            {path: 'submitter', type: 'User', nullable: true},
            {path: 'controller', type: 'User', nullable: true},
            {path: 'validator', type: 'User', nullable: true},
            {
                path: "needsTypes",
                fieldPath: ['needs.name'],
                $f: function (application, context, callback) {
                    callback(null, application.needs.map(need => _.get(need, 'name', '')).join(' - '));
                }
            },
            {
                path: "totalTotalAmounts",
                fieldPath: ['needs.totalAmount'],
                $f: function (application, context, callback) {
                    const total = application.needs.reduce((acc, need) => {
                        return acc + parseInt(need.totalAmount)
                    }, 0)
                    callback(null, total);
                }
            },
            {
                path: "totalRequestedAmounts",
                fieldPath: ['needs.requestedAmount'],
                $f: function (application, context, callback) {
                    const total = application.needs.reduce((acc, need) => {
                        return acc + parseInt(need.requestedAmount)
                    }, 0)
                    callback(null, total);
                }
            },
            {
                path: "totalAwardedAmounts",
                fieldPath: ['needs.awardedAmount'],
                $f: function (application, context, callback) {
                    const total = application.needs.reduce((acc, need) => {
                        if(!need.awardedAmount) return 0
                        return acc + parseInt(need.awardedAmount)
                    }, 0)
                    callback(null, total)
                }
            },
            {
                path: 'totalProposedAmounts',
                fieldPath: ['payments.status.id', 'payments.proposedAmount'],
                $f: function (application, context, callback) {
                    const total = application.payments
                        .filter(payment => _.get(payment, 'status.id') !== 'refused')
                        .reduce((acc, payment) => {
                            return acc + parseInt(payment.amount)
                        }, 0)
                    callback(null, total)
                }
            },
            {
                path: 'totalValidatedAmounts',
                fieldPath: ['payments.status.id', 'payments.proposedAmount'],
                $f: function (application, context, callback) {
                    const total = application.payments
                        .filter(payment => _.get(payment, 'status.id') === 'validated')
                        .reduce((acc, payment) => {
                            return acc + parseInt(payment.amount)
                        }, 0)
                    callback(null, total)
                }
            },
            {
                path: "fundingRate",
                fieldPath: ["totalTotalAmounts", "totalRequestedAmounts"],
                $f: function (application, context, callback) {
                    const fundingRate = application.totalTotalAmounts > 0
                        ? _.round((application.totalRequestedAmounts / application.totalTotalAmounts) * 100)
                        : 0

                    callback(null, `${fundingRate}%`)
                }
            },
            {
                path: "paymentRate",
                fieldPath: ["totalAwardedAmounts", "totalValidatedAmounts"],
                $f: function (application, context, callback) {
                    const fundingRate = application.totalAwardedAmounts > 0
                        ? _.round((application.totalValidatedAmounts / application.totalAwardedAmounts) * 100)
                        : 0

                    callback(null, `${fundingRate}%`)
                }
            },
            {
              path: 'organizationName',
              fieldPath: ['submitter.id'],
              $f: function (application, context, callback) {
                  const submitterId = _.get(application, 'submitter.id')
                  if(!submitterId) {
                      return callback(null, '')
                  }
                  global.app.C.CUser.get({kpUser: new global.ObjectID(submitterId)}, {
                      ...basicContext(context),
                      fieldPath: ['id', 'organizationName']
                  }, (error, cUser) => {
                      callback(error, cUser.organizationName)
                  })
              }
            },
            {
                path: 'fullName',
                fieldPath: ['applicationNumber', 'organizationName', 'object'],
                f: function () {
                    return `${this.applicationNumber} - ${this.organizationName} - ${this.object}`
                }
            },
            {
                path: "sequence",
                unique: true,
                ps: {
                    object: [{
                        type: "nextSequence",
                        sequenceId: "reliefFundApplicationSequence",
                        formatResult: function(result) {
                            return result.toString().padStart(4, '0') // Formats the sequence number, e.g., "0001"
                        }
                    }]
                }
            },
            {
                path: 'incompletePayments',
                nullable: true,
                fieldPath: ['status.id', 'payments.status.id'],
                $f: function(application, context, callback) {
                    const incompletePayments = application.payments?.filter(payment => _.get(payment, 'status.id') === 'incomplete').length

                    if(!incompletePayments) {
                        return callback(null, null)
                    }
                    return callback(
                        null,
                        {id: incompletePayments.toString(), number: incompletePayments, bStyle: 'warning', style: {padding: '0px 6px', float: 'right'}}
                    )
                }
            },
            {
                path: "buttons",
                $f: function (application, context, callback) {
                    const buttons = getButtonsForModule(application, context);
                    callback(null, buttons);
                }
            },
            {
                path: "noDeleteButtonAccess",
                $f: function (application, context, callback) {
                    // Check if the application status is NOT 'draft' or 'questioned'
                    const noDeleteAccess = !['draft', 'questioned'].includes(_.get(application, 'status.id'))

                    // Call the callback function with the result
                    // true means no delete access, false means delete access is allowed
                    callback(null, noDeleteAccess)
                }
            }
        ],
        filters: [
            {
                name: 'isAccepted',
                isDefault: false,
                query: () => ({status : 'accepted'})
            },
            {
                name: 'userIsSubmitter',
                isDefault: false,
                query: context => {
                    const userId = _.get(context, 'user.id')
                    return userId ? {submitter: global.ObjectID(userId)} : {_id: null}
                }
            },
            {
                name: 'hasOngoingPayment',
                isDefault: false,
                async: true,
                query: (context, callback) => {
                    global.app.C.Payment.collection.find({status: 'ongoing'}).toArray((e, payments) => {
                        return callback(e, {_id: {$in: payments.map(payment => payment.reliefFund)} })
                    })
                }
            },
            {
                name: 'userHasAuthorization',
                async: true,
                isDefault: false,
                query: async (context, callback) => {
                    const userId = _.get(context, 'user.id')
                    const moduleId = context.clientContext.moduleId
                    const authorizationMapping = {
                        'm-C-study': 'studyAuthorization',
                        'm-C-decision': 'decisionAuthorization',
                        'm-C-reliefFundPayment': 'paymentAuthorization',
                        'm-C-followUp': ['studyAuthorization', 'decisionAuthorization', 'paymentAuthorization']
                    };

                    // Determine the authorization property based on the current module ID
                    const authorizationProperties = authorizationMapping[moduleId]
                    try {
                        const cUser = await global.app.C.CUser.get({kpUser: global.ObjectID(userId)}, {
                            ...basicContext(context),
                            fieldPath: [
                                'id',
                                'functions.studyAuthorization',
                                'functions.decisionAuthorization',
                                'functions.paymentAuthorization'
                            ]
                        })
                        const userHasAuthorization = cUser.functions.some(userFunction =>{
                            if( typeof authorizationProperties === 'string' ) {
                                return !!userFunction[authorizationProperties]
                            }
                            if( _.isArray(authorizationProperties) ) {
                                return authorizationProperties.some(authorizationProperty => !!userFunction[authorizationProperty])
                            }
                            return false
                        })
                        return callback(null,
                            moduleId === 'm-C-followUp'
                                ? userHasAuthorization ? {} : {submitter: global.ObjectID(userId)}
                                : userHasAuthorization ? {} : {_id: null}
                    )
                    } catch (e) {
                        callback(e)
                    }

                }
            }
        ],
        ps: {
            context: [{
                $$u: function (context, callback) {
                    if (this.options.accessType === "S" && context.restAction && context.restAction.crudType === "C") {
                        context.internalFieldPath = [
                            ...new Set([
                                ...context.internalFieldPath,
                                "sequence" // Ensures 'sequence' is included for sequence generation on creation
                            ])
                        ];
                    }
                    callback(null, context)
                }
            }]
        },
        authorizationsValidation: function (cUser, context, callback) {
            const moduleId = context.clientContext.moduleId

            // Mapping module IDs to their corresponding authorization properties
            const authorizationMapping = {
                'm-C-submission': 'submissionAuthorization',
                'm-C-study': 'studyAuthorization',
                'm-C-decision': 'decisionAuthorization',
                'm-C-reliefFundPayment': 'paymentAuthorization'
            };

            // Determine the authorization property based on the current module ID
            const authorizationProperty = authorizationMapping[moduleId]

            if (authorizationProperty) {
                const hasAuthorization = cUser.functions.some(userFunction => !!userFunction[authorizationProperty])

                if (!hasAuthorization) {
                    // Adjust the error message based on the specific authorization failure
                    return callback(new Errors.ValidationError(`${authorizationProperty}Denied`))
                }
            }

            return callback()
        },

        checkboxesValidation: function (cUser, newObject, context, callback) {
            const moduleId = context.clientContext.moduleId
            const buttonAction = context.action

            if(moduleId !== 'm-C-submission' || buttonAction !== 'submit') return callback()

            const checkboxesToValidate = [
                'associationInformationUpToDate',
                'statementOfHonestyAndAccuracy'
            ]
            if (!!cUser.organization?.hasShelters) {
                checkboxesToValidate.push('shelterInformationUpToDate')
                checkboxesToValidate.push('shelterCapacityInformationUpToDate')
            }
            let missingCheckbox
            checkboxesToValidate.some(checkbox => {
                const isMissing = !newObject[checkbox]
                if(isMissing) {
                    missingCheckbox = checkbox
                    return true
                }
            })

            if (!!missingCheckbox) return callback(new Errors.ValidationError(context.tc('checkboxIsRequired', {checkbox: context.tc(missingCheckbox)})))
            return callback()

        },
        checkUniqueNeedsByType: function (newObject, context, callback) {
            const natureOfHelpSet = new Set()
            const hasDuplicateNeed = newObject.needs.some(need => {
                if (natureOfHelpSet.has(need.natureOfHelp.id)) {
                    return true; // Duplicate found
                }
                natureOfHelpSet.add(need.natureOfHelp.id);
                return false;
            });

            if (hasDuplicateNeed) {
                return callback(new Errors.ValidationError(context.tc('needsMustBeUniqueByType')));
            }

            callback();
        },
        needsDecisionModuleValidation: function (newObject, context, callback) {
            const moduleId = context.clientContext.moduleId
            const buttonAction = context.action

            if(moduleId !== 'm-C-decision' || buttonAction !== 'accept') return callback()
            const requiredFields = ['awardedAmount', 'form']
            let missingField
            const isMissingField = newObject.needs.some( need => {
                return requiredFields.some(path => {
                    if(!need[path]) {
                        missingField = path
                        return true
                    }
                    return false
                })
            })

            if (isMissingField) return callback(new Errors.ValidationError(context.tc('needMissingField', {path: context.tc(missingField)})))
            return callback()
        },
        cannotModifyProcessedPayments: function (newObject, oldObject, context, callback) {
            const moduleId = context.clientContext.moduleId
            if(moduleId !== 'm-C-reliefFundPayment') return callback()

            const processedPayments = newObject.payments.filter(payment => ['validated', 'refused'].includes(payment.status?.id))
            if(processedPayments.length === 0) return callback()

            const hasModifiedProcessedPayment = processedPayments.some(payment => {
                const previousValues = oldObject.payments.find(previousValue => previousValue.id === payment.id)
                return previousValues.need.id !== payment.need.id
                    || previousValues.amount !== payment.amount
                    || previousValues.account.id !== payment.account.id
            })

            if(hasModifiedProcessedPayment) return callback(new Errors.ValidationError(context.tc('Vous ne pouvez pas modifier un paiement traité')))

            return callback()
        },
        checkNeedsBalance: function (newObject, oldObject, context, callback) {
            const moduleId = context.clientContext.moduleId
            if(moduleId !== 'm-C-reliefFundPayment') return callback()
            const groupedByNeed = _.groupBy(newObject.payments, payment => _.get(payment, 'need.id'))
            const hasNegativeBalance = Object.keys(groupedByNeed).some(needId => {
                const payments = groupedByNeed[needId]
                const firstPayment = payments[0]
                const totalPayments = payments
                    .filter(payment => _.get(payment, 'status.id') !== 'refused')
                    .reduce((acc, payment) => acc + parseInt(payment.amount), 0)
                return parseInt(totalPayments) - parseInt(firstPayment.awardedAmount) > 0
            })

            if(hasNegativeBalance) return callback(new Errors.ValidationError('Vous ne pouvez pas valider avec un solde négatif'))
            return callback()
        },
        validateSave: async function (newObject, oldObject, context, callback) {
            let cUser
            try {
                cUser = await global.app.C.CUser.get({kpUser: global.ObjectID(context.user.id)}, {
                    ...basicContext(context),
                    fieldPath: [
                        'id',
                        'functions.id',
                        'functions.submissionAuthorization',
                        'functions.studyAuthorization',
                        'functions.decisionAuthorization',
                        'functions.paymentAuthorization',
                        'organization.id',
                        'organization.hasShelters'
                    ]
                })
            } catch (e) {
                return callback(e)
            }
            context.data.organization = cUser.organization
            async.series([
                callback => this.authorizationsValidation(cUser, context, callback),
                callback => this.checkboxesValidation(cUser, newObject, context, callback),
                callback => this.checkUniqueNeedsByType(newObject, context, callback),
                callback => this.needsDecisionModuleValidation(newObject, context, callback),
                callback => this.cannotModifyProcessedPayments(newObject, oldObject, context, callback),
                callback => this.checkNeedsBalance(newObject, oldObject, context, callback)
            ], callback)
        },
        beforeSave: function(newObject, oldObject, context, callback) {
            if (context.restAction && context.restAction.crudType === "C") {
                // Initialize status to 'draft' for new records
                newObject.status = {id: 'draft'}
                // Prefix the applicationNumber with the current year
                const currentYear = new Date().getFullYear()
                newObject.applicationNumber = `FSE-${currentYear}-${newObject.sequence}`

                // set current user as submitter of the application
                newObject.submitter = _.pick(context.user, ['id', 'firstname', 'lastname', 'mail'])

                // set user organization
                newObject.organization = context.data.organization
            }

            const moduleId = context.clientContext.moduleId
            const buttonAction = context.action

            // Ensure status does not change when the save button is clicked
            if (buttonAction === 'save') {
                return callback(null, newObject, oldObject)
            }


            newObject.lastComment = _.last(_.orderBy(newObject.comments, 'date'))?.text

            // Status transitions based on moduleId and button action
            switch (moduleId) {
                case 'm-C-submission':
                    if (buttonAction === 'submit') {
                        newObject.status.id = 'ongoing'
                    }
                    if(buttonAction === 'update') {
                        newObject.status.id = 'updated'
                    }
                    break
                case 'm-C-study':
                    if (buttonAction === 'examine') {
                        newObject.status.id = 'questioned'
                        newObject.controller = _.pick(context.user, ['id', 'name'])
                    } else if (buttonAction === 'accept') {
                        newObject.status.id = 'controlled'
                        newObject.controller = _.pick(context.user, ['id', 'name'])
                    }
                    break
                case 'm-C-decision':
                    if (buttonAction === 'accept') {
                        newObject.status.id = 'accepted'
                        newObject.validator = _.pick(context.user, ['id', 'name'])
                    } else if (buttonAction === 'refuse') {
                        newObject.status.id = 'refused'
                        newObject.validator = _.pick(context.user, ['id', 'name'])
                    } else if (buttonAction === 'examine') {
                        newObject.status.id = 'questioned'
                        newObject.validator = _.pick(context.user, ['id', 'name'])
                    }
                    break
                case 'm-C-reliefFundPayment':
                    if (buttonAction === 'closePayments') {
                        newObject.status.id = 'closed'
                    }
                    break
                case 'm-C-followUp':
                    newObject.comments.push({
                        user: _.pick(context.user, ['id', 'name']),
                        text: 'Pièces justificatives : Mises à jour',
                        date: moment().format("YYYY-MM-DD HH:mm")
                    })
                    return callback(null, newObject, oldObject)
            }

            if(moduleId === 'm-C-reliefFundPayment' && buttonAction === 'transmitPayments') {
                newObject.payments = newObject.payments.map(
                    payment => {
                        const statusId = _.get(payment, 'status.id')
                        if(['draft', 'incomplete'].includes(statusId)) {
                            return {
                                ...payment,
                                status: {
                                    id: 'ongoing'
                                },
                                comments: [
                                    ...payment.comments,
                                    {
                                        user: _.pick(context.user, ['id', 'name']),
                                        text: statusId === 'draft'
                                            ? `Saisie Paiement : ${payment.amount} € - ${payment.account.name}`
                                            : `Mise à jour Paiement : ${payment.amount} € - ${payment.account.name}`,
                                        date: moment().format("YYYY-MM-DD HH:mm")
                                    }
                                ]
                            }
                        }
                        return payment
                    }
                )
            }

            const statusObject = refundApplicationStatusObjects.find(status => status.id === newObject.status.id)

            newObject.comments.push({
                user: _.pick(context.user, ['id', 'name']),
                text: `${newObject.applicationNumber}: ${statusObject.name}`,
                date: moment().format("YYYY-MM-DD HH:mm")
            })

            // Proceed with saving
            callback(null, newObject, oldObject)
        },
        afterSave: async function (newObject, oldObject, context, callback) {
            const buttonAction = context.action
            const moduleId = context.clientContext.moduleId

            if (buttonAction === 'save') {
                return callback()
            }

            let mails = []

            //Prepare email to notify the submitter
            const notificationEmail = prepareNotificationEmail(newObject, context)

            if(notificationEmail) mails.push(notificationEmail)

            if(['submit', 'update'].includes(buttonAction)) {
                //Prepare controllers emails
                const controllersEmails = await prepareRoleBasedNotificationEmail("studyAuthorization", newObject, context)
                controllersEmails.forEach(mail => mails.push(mail))
            }

            /*

            if(buttonAction === 'accept' && moduleId === 'm-C-study') {
                //Prepare validators emails
                const validatorsEmails = await prepareRoleBasedNotificationEmail("decisionAuthorization", newObject, context)
                validatorsEmails.forEach(mail => mails.push(mail))
            }

            if(buttonAction === 'accept' && moduleId === 'm-C-decision') {
                //Prepare validators emails
                const payersEmails = await prepareRoleBasedNotificationEmail("paymentAuthorization", newObject, context)
                payersEmails.forEach(mail => mails.push(mail))
            }

            if(buttonAction === 'transmitPayments' && moduleId === 'm-C-reliefFundPayment') {
                //Prepare validators emails
                const payersEmails = await prepareRoleBasedNotificationEmail("decisionAuthorization", newObject, context)
                payersEmails.forEach(mail => mails.push(mail))
            }

             */
            if(moduleId === 'm-C-followUp' && buttonAction === 'updateDocuments') {
                //Prepare validators emails
                const controllersEmails = await prepareRoleBasedNotificationEmail("paymentAuthorization", newObject, context)
                controllersEmails.forEach(mail => mails.push(mail))
            }

            getCNDANotificationData(context.group.id)
                .then(data => global.ioSocket.emit('afterSave', data))

            return global.mailer.sendMail(mails, (error) => {
                console.log(error)
                return callback()
            })
        }
    }
]

function getNotificationEmailContent(newObject, context) {
    const buttonAction = context.action
    const moduleId = context.clientContext.moduleId
    switch (buttonAction) {
        case 'submit':
            return {
                subject: `CNDA - Dossier Fonds de Secours Exceptionnel N° ${newObject.applicationNumber}, Enregistré`,
                template: 'user_submission_notification.html'
            }
        case 'update':
            return {
                subject: `CNDA - Dossier Fonds de Secours Exceptionnel N° ${newObject.applicationNumber}, Mis à jour`,
                template: 'user_update_notification.html'
            }
        case 'examine':
            return {
                subject: `CNDA - Dossier Fonds de Secours Exceptionnel N° ${newObject.applicationNumber}, Compléments demandés`,
                template: 'user_examination_notification.html'
            }
        case 'refuse':
            return {
                subject: `CNDA - Dossier Fonds de Secours Exceptionnel N° ${newObject.applicationNumber}, Refusé`,
                template: 'user_refusal_notification.html'
            }
        case 'accept':
            if(moduleId === 'm-C-study') {
                return {
                    subject: `CNDA - Dossier Fonds de Secours Exceptionnel N° ${newObject.applicationNumber}, En attente de décision`,
                    template: 'user_controller_acceptance_notification.html'
                }
            } else if(moduleId === 'm-C-decision') {
                return {
                    subject: `CNDA - Dossier Fonds de Secours Exceptionnel N° ${newObject.applicationNumber}, Accepté`,
                    template: 'user_validator_acceptance_notification.html'
                }
            }
            break
    }
    return undefined
}

function prepareNotificationEmail(newObject, context) {
    const isCreationOperation = context.restAction && context.restAction.crudType === "C"
    const defaultMail = prepareDefaultMail()
    const content = getNotificationEmailContent(newObject, context)

    if(!content) return undefined

    return _.defaults({
        to: isCreationOperation ? newObject.submitter.mail : decrypt(newObject.submitter.mail),
        content: content.template,
        subject: { template: content.subject },
        context: {
            firstname: newObject.submitter.firstname,
            applicationNumber: newObject.applicationNumber,
            lastComment: newObject.lastComment,
            groupModelId: context.groupModel.id,
            baseUrl: global.isProd
                ? `https://${context.host}`
                : `http://localhost:3000`
        }
    }, defaultMail)
}

async function prepareRoleBasedNotificationEmail(authorization, newObject, context) {
    const recipients = await getUsersWithAuthorization(authorization)
    const defaultMail = prepareDefaultMail()
    const buttonAction = context.action
    const moduleId = context.clientContext.moduleId

    const content = {}

    switch (authorization) {
        case 'studyAuthorization':
            if(buttonAction === 'submit') {
                content.template = 'controller_submission_notification.html'
                content.subject = `CNDA - Dossier Fonds de Secours Exceptionnel N° ${newObject.applicationNumber}, Enregistré`
            }
            if(buttonAction === 'update') {
                content.template = 'controller_update_notification.html'
                content.subject = `CNDA - Dossier Fonds de Secours Exceptionnel N° ${newObject.applicationNumber}, Mis a jour`
            }
            break
        case 'decisionAuthorization':
            if(moduleId === 'm-C-study') {
                content.template = 'validator_control_notification.html'
                content.subject = `Notification de contrôle`
            }
            if(moduleId === 'm-C-reliefFundPayment') {
                content.template = 'validator_payment_notification.html'
                content.subject = `Notification de saisie de paiements`
            }
            break
        case 'paymentAuthorization':
            if(buttonAction === 'updateDocuments') {
                content.template = 'documents_update_notification.html'
                content.subject = `CNDA - Dossier Fonds de Secours Exceptionnel N° ${newObject.applicationNumber}, Suivi paiement mis à jour`
            }
    }

    return recipients.map(recipient => {
        return _.defaults(
            {
                to: recipient.mail && decrypt(recipient.mail),
                content: content.template,
                subject: { template: content.subject },
                context: {
                    firstname: recipient.firstname,
                    applicationNumber: newObject.applicationNumber
                }
            },
            defaultMail
        )
    })
}
